<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Company Insights</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 col-xl-12">
					<div class="card">
						<div class="card-header justify-content-between">
							<h4 class="card-header-title">Companies Growth</h4>
						</div>
						<div class="card-body">
							<bar-chart v-if="companiesGrowthData" class="chart barchart" :chart-data="companiesGrowthData"
								:options="barChartOptions"></bar-chart>
							<div v-if="loadingCompaniesGrowthData" class="d-flex justify-content-center align-items-center">
								<div class="spinner-border" role="status"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import BarChart from '@/components/charts/BarChart'
import { axiosInstance } from '@/plugins/axios'
import { getYearlyBarChartData } from '@/utils/chart'
import { ref } from 'vue'

const companiesGrowthData = ref(null)
const loadingCompaniesGrowthData = ref(true)
const errorLoadingCompaniesGrowthData = ref(false)
const barChartOptions = ref({
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false
        },
        offset: true
      }
    ],
    yAxes: [
      {
        gridLines: {
          borderDash: [8, 4],
          color: '#F6F6F7'
        },
        ticks: {
          stepSize: 2
        }
      }
    ]
  }
})

const fetchChartData = () => {
  loadingCompaniesGrowthData.value = true
  errorLoadingCompaniesGrowthData.value = false

  axiosInstance
    .get('/v1/corporates/graph')
    .then((res) => {
      companiesGrowthData.value = getYearlyBarChartData(res.data)
    })
    .catch(() => {
      errorLoadingCompaniesGrowthData.value = true
    })
    .finally(() => (loadingCompaniesGrowthData.value = false))
}

fetchChartData()

</script>
